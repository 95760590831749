import { NativeBridgeMessageTypes } from "@converge-collective/common/models/NativeAppBridge";
import { useEffect } from "react";
import { postToRN } from "~/components/ReactNativeBridge";
import { withLoggedInState } from "~/lib/withLoggedInState";

const promptNotificationDelayMs = 10;
export const PromptAllowPushNotifications = withLoggedInState(
  function PromptNotifications() {
    // ensure this is only run once on mount!
    useEffect(() => {
      // if the user is signed in and they're in the react native app, prompt them
      // to allow notifications (rather than when they first open the app)
      // wait to prompt for a few seconds while the newsfeed loads to avoid
      // performance issues
      setTimeout(() => {
        console.count("prompting for notifications");
        postToRN(NativeBridgeMessageTypes.PromptAllowNotifications);
      }, promptNotificationDelayMs);
    }, []);

    return <></>;
  }
);
