import React from "react";
import { Button, Dialog, Fade, Backdrop, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import RecognizeStepper from "~/components/RecognizeStepper";
import { Network } from "@converge-collective/common/models/Network";
import { WithRef } from "@converge-collective/common/models/Base";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    modal: {
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(0, 0, 0),
      borderRadius: 8,
    },
  })
);

export default function RecognizeModal(props: {
  network: WithRef<Network>;
}): React.ReactElement {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.root}>
        <Button
          sx={{ mb: 2 }}
          onClick={handleOpen}
          variant="contained"
          fullWidth={true}
        >
          RECOGNIZE
        </Button>
      </div>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/converge-mt.appspot.com/o/images%2FConverge%20Photos%2Fsuccess.jpg?alt=media&token=608d1e29-55db-4f28-86f1-d77710033e82"
              className="ModalPhoto"
              width="600px"
              alt="Thank You"
            />
          </Box>
        </Fade>
        <RecognizeStepper handleClose={handleClose} network={props.network} />
      </Dialog>
    </div>
  );
}
