import { WithRef } from "@converge-collective/common/models/Base";
import { isCourseChallenge } from "@converge-collective/common/models/ChallengeV2";
import { Network } from "@converge-collective/common/models/Network";
import { LiteProfile } from "@converge-collective/common/models/Profile";
import School from "@mui/icons-material/School";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { useAssignedChallenges } from "~/hooks/useChallenges";
import { CourseCard } from "../courses/CourseCard";

export function MyChallenges({
  network,
  profile,
}: {
  network: WithRef<Network>;
  profile: WithRef<LiteProfile>;
}): React.ReactElement {
  const { assignedChallenges } = useAssignedChallenges(network, profile);

  if (assignedChallenges.length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Chip
        icon={<School color="primary" />}
        sx={{
          mb: 2,
          display: "flex",
          "& .MuiChip-label": { width: "100%" },
        }}
        label={
          <Typography sx={{ fontWeight: "bold" }}>Assigned Courses</Typography>
        }
      />
      <Stack direction="column" spacing={2}>
        {assignedChallenges.map((c) => (
          <div key={c.id}>
            {isCourseChallenge(c) && <CourseCard course={c} />}

            {/*
            TODO re-enable with blocks separately
            c.blocks
            .map(shallowTSToDates)
            .filter((b) => b.startAt >= addMonths(startOfToday(), -1))
            .map((b, i) => (
              <ChallengeV2Block
                sx={{ mb: 2 }}
                key={i}
                network={network}
                block={b}
                isTemplate={false}
                challenge={c}
                isEditable={false}
              />
            ))
          */}
          </div>
        ))}
      </Stack>
    </Box>
  );
}
