import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import { LinearProgress } from "@mui/material";
import { User } from "firebase/auth";
import React from "react";
import { useLoggedInState } from "~/lib/useLoggedInState";
import Layout, { defaultProps, LayoutProps } from "./Layout";
import { useRouter } from "next/router";

export type LayoutNetworkChildPropsWithAuth = {
  auth: true;
  network: WithRef<Network>;
  // networkMembership: NetworkMembership;
  user: User;
  profile: WithRef<Profile>;
};

export type LayoutNetworkChildPropsWithoutAuth = {
  auth: false;
  network: WithRef<Network>;
};

export type LayoutNetworkChildProps =
  | LayoutNetworkChildPropsWithAuth
  | LayoutNetworkChildPropsWithoutAuth;

export type LayoutWithNetworkProps = LayoutProps & {
  noAuthForPublicNetwork?: boolean;
  Component: (props: LayoutNetworkChildProps) => React.ReactElement;
};

// This is intended to wrap all /[network]/* routes, and serves to fetch the
// network and show a loader while fetching, and once the network is obtained it
// passes it as a prop to the page content
//
// It works either logged in or out, depending on whether the auth prop is true
// or false.
export default function LayoutWithNetwork(
  p: LayoutWithNetworkProps
): React.ReactElement {
  const router = useRouter();
  const { query: urlQuery } = router;
  const networkSlug = urlQuery.network as string;

  const { user, profile, network } = useLoggedInState();
  const [isSwitchingNetworks, setIsSwitchingNetworks] = React.useState(false);
  const { Component, ...props } = p;

  // TODO: How can we prevent showing the auth view while we wait for the
  // network to load?
  //
  // Should we indicate that LayoutWithNetwork is still loading={true} while we
  // wait for network to become available?

  // auth can:
  // - be enabled/disabled via the `auth` prop
  // - or conditionally disabled for public networks via the
  // `noAuthForPublicNetwork` prop
  const defaultAuth = true;
  const auth =
    p.auth ??
    p.auth ??
    (p.noAuthForPublicNetwork === true ? !network?.public : defaultAuth);

  const isLoading = p.noAuthForPublicNetwork ? !network : false;

  const layoutProps: LayoutProps = {
    ...props,
    // if `noAuthForPublicNetwork` is true, wait for the network to load
    isLoading,
  };

  // console.log("LayoutWithNetwork", { p, isLoading, auth, network, profile });
  const childProps: LayoutNetworkChildProps | undefined =
    network &&
    (auth && network && user && profile
      ? {
          auth: true,
          user: user,
          profile: profile,
          network: network,
          // networkMembership: networkMembership,
        }
      : {
          auth: false,
          network: network,
        });

  console.count("LayoutWithNetwork");
  console.log("LayoutWithNetwork", { isLoading, auth, network, profile });

  React.useEffect(() => {
    setIsSwitchingNetworks(network?.slug !== networkSlug);
  }, [network?.slug, networkSlug]);

  return (
    <Layout {...layoutProps} auth={auth}>
      {childProps && !isSwitchingNetworks ? (
        <Component {...childProps} />
      ) : (
        <LinearProgress />
      )}
    </Layout>
  );
}

LayoutWithNetwork.defaultProps = { ...defaultProps, auth: undefined };
