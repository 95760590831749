import { converters } from "~/lib/converter";
import { WithRef } from "@converge-collective/common/models/Base";
import {
  Event,
  EventVisibilities,
} from "@converge-collective/common/models/Event";
import { Group } from "@converge-collective/common/models/Group";
import { Network } from "@converge-collective/common/models/Network";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  collection,
  limit,
  orderBy,
  query,
  where,
  and,
} from "firebase/firestore";
import { isEmpty, sortBy, uniqBy } from "lodash";
import React, { useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import EventCard from "../activity/EventCard";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";

export default function UpcomingEvents({
  network,
  group,
}: {
  network: WithRef<Network>;
  group?: WithRef<Group>;
}): React.ReactElement {
  const [now] = useState(new Date());
  const theme = useTheme();
  const firestore = useFirestore();
  const mdDownMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = mdDownMatch;

  const maxEvents = isMobile ? 1 : 3;

  const baseQuery = query(
    collection(network.ref, "events").withConverter(converters.event.read),
    and(where("startAt", ">", now)),
    limit(maxEvents)
  );

  console.log("UpcomingEvents", { group });

  // if a group is passed in, look up its events, including private events
  const eventsRef = (
    group
      ? query(baseQuery, where("visibleByIds", "array-contains", group.id))
      : baseQuery
  ).withConverter(converters.event.read);

  const { data: eventsRaw } = useFirestoreCollectionData(eventsRef);

  // we need a separate query to find events matching the group's category
  const extraEventsRef =
    group && !isEmpty(group.interestedSubCategoryIds)
      ? query(
          baseQuery,
          where(
            "categoryInfo.subCategoryId",
            "in",
            group.interestedSubCategoryIds
          ),
          where("startAt", ">", now),
          orderBy("startAt", "asc")
        )
      : collection(firestore, "noop").withConverter(converters.event.read);
  const { data: extraEventsRaw } = useFirestoreCollectionData(extraEventsRef);

  // NOTE: this is a hack to filter out private events since firestore query is
  // so limited :(
  const filter = group
    ? (event: Event) =>
        event.visibility === EventVisibilities.Public ||
        event.visibleByIds?.includes(group.id)
    : (event: Event) =>
        event.visibility === EventVisibilities.Public &&
        event.latestDocUpdate?.status !== DocStatuses.Deleted;

  // use uniqBy since the two queries could both fetch the same event
  const events = sortBy(
    uniqBy(
      [...(eventsRaw || []), ...(extraEventsRaw || [])].filter(filter),
      "id"
    ),
    "startAt"
  );

  return (
    <>
      {events?.length > 0 && (
        <Box sx={{ position: "relative", top: 0, mb: 2 }}>
          {events.map((event) => (
            <Box
              key={event.id}
              display="flex"
              mt={2}
              alignItems="center"
              justifyContent="center"
            >
              <EventCard isCompact={isMobile} network={network} event={event} />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
