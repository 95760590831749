import { TextField } from "@mui/material";
import { useState } from "react";

export default function RecognizeNote({
  setNote,
}: {
  setNote: (note: string) => void;
}) {
  const [value, setValue] = useState("");
  return (
    <TextField
      fullWidth
      value={value}
      multiline
      onChange={(e) => {
        setValue(e.target.value);
        setNote(e.target.value);
      }}
      label="Tell your colleague why you appreciate their hard work"
    />
  );
}
