import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { DefaultPeerRecognitionSettings } from "@converge-collective/common/models/NetworkSettings";
import { PeerRecognitionAwardType } from "@converge-collective/common/models/PeerRecognition";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

export default function RecognizeAwards({
  network,
  setAwardType,
  value,
}: {
  network: WithRef<Network>;
  setAwardType: (at: PeerRecognitionAwardType) => void;
  value?: string;
}): React.ReactElement {
  console.log("RecognizeAwards", { value });

  const awardTypes = (
    network?.settings?.peerRecognition || DefaultPeerRecognitionSettings
  ).awardTypes;

  const awardTypesById = Object.fromEntries(
    awardTypes.map((at) => [at.id, at])
  );

  const handleChange = (event: SelectChangeEvent) => {
    const id: string = event.target.value;
    setAwardType(awardTypesById[id]);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="list-of-awards">Award</InputLabel>
      <Select
        labelId="list-of-awards"
        id="list-of-awards-outlined"
        value={value || ""}
        onChange={handleChange}
        label="Award"
      >
        {awardTypes.map(({ id, description }) => (
          <MenuItem key={id} value={id}>
            {description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
