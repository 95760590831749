import {
  ActivityLogKinds,
  PeerRecognitionLog,
  computePoints,
} from "@converge-collective/common/models/ActivityLog";
import {
  WithCreatedAt,
  WithRef,
} from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import {
  PeerRecognitionAwardType,
  PeerRecognitionPointValue,
} from "@converge-collective/common/models/PeerRecognition";
import {
  ActivityPost,
  POST_TYPE,
} from "@converge-collective/common/models/Post";
import {
  NanoProfile,
  nanoProfile,
} from "@converge-collective/common/models/Profile";
import { Celebration } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import RecognizeAwards from "~/components/RecognizeAwards";
import RecognizePoints from "~/components/RecognizePoints";
import RecognizeUsernames from "~/components/RecognizeUsernames";
import useProfile from "~/hooks/useProfile";
import { converters } from "~/lib/converter";
import { postDetailRoute } from "~/routes";
import RecognizeNote from "./RecognizeNote";
import Wrap from "./Wrap";
import {
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
);

export default function RecognizeStepper(props: {
  network: WithRef<Network>;
  handleClose: () => void;
}): React.ReactElement {
  const classes = useStyles();
  const firestore = useFirestore();
  const [activeStep, setActiveStep] = React.useState(0);
  const { data: user } = useUser();
  const [profile] = useProfile(user?.uid);

  // giver, receiver, note, pointsValue, awardType
  const [note, setNote] = useState<string>();
  const [awardType, setAwardType] = useState<PeerRecognitionAwardType>();
  const [pointsValue, setPointsValue] = useState<PeerRecognitionPointValue>();
  const [receiver, setReceiver] = useState<NanoProfile>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data: networkMemberships } = useFirestoreCollectionData(
    query(
      collection(props.network.ref, "members"),
      // where("profile.name", ">", ""),
      where("profile.id", "!=", `${user?.uid}`)
    ).withConverter(converters.networkMembership.read)
  );
  const profiles = (networkMemberships || []).map((nm) => nm.profile);

  console.log("RecognizeStepper", {
    networkMemberships,
    profiles,
  });

  const steps = [
    {
      label: receiver ? `Colleage: ${receiver.name}` : "Add Colleague",
      validation: "Please select a colleague to recognize",
      field: receiver,
    },
    {
      label: awardType
        ? `Award Type: ${awardType.description}`
        : "Select Award Type",
      validation: "Please select an award type",
      field: awardType,
    },
    {
      label: pointsValue
        ? `Points: ${pointsValue.points} - ${pointsValue.description}`
        : "Add Points",
      validation: "Please select a point value",
      field: pointsValue,
    },
    {
      label: "Add Note",
      validation: "Please add a note",
      field: note,
    },
  ];

  const [error, setError] = useState<string>("");

  const resetForm = () => {
    setError("");
    setReceiver(undefined);
    setAwardType(undefined);
    setPointsValue(undefined);
    setNote(undefined);
    setActiveStep(0);
  };

  const handleClose = () => {
    resetForm();
    props.handleClose();
  };

  const handleSubmit = async () => {
    if (!(profile && receiver && note && pointsValue && awardType)) {
      console.error("Missing required fields");
      return;
    }
    setIsSubmitting(true);
    const giver = nanoProfile(profile);
    const peerRecognitionActivity: PeerRecognitionLog = {
      kind: ActivityLogKinds.PeerRecognitionLog,
      createdAt: new Date(),
      profile: receiver,
      uid: receiver.id,
      description: `<b>${profile.name}</b> gave <b>${receiver.name}</b> recognition! <b>${awardType.description} - ${pointsValue.description}</b>: ${note}`,
      date: new Date(),
      network: props.network,
      recognition: {
        giver,
        receiver: receiver,
        note,
        pointsValue,
        awardType,
      },
    };
    console.log("giving peer recognition", { peerRecognitionActivity });
    try {
      const activityLogRef = await addDoc(
        collection(
          firestore,
          "profiles",
          receiver.id,
          "activityLogs"
        ).withConverter(converters.peerRecognitionLog.write),
        peerRecognitionActivity
      );

      const al = (
        await getDoc(activityLogRef.withConverter(converters.activityLog.read))
      ).data();
      if (al) {
        const activityLog = computePoints(al);

        const postRef = doc(props.network.ref, "posts", activityLogRef.id);
        const url = postDetailRoute(props.network.slug, postRef.id);
        // TODO this team is wrong - we need the team of the receiver not the
        // giver of recognition, so mark it null for now and fix it later.
        const team = null; // networkMembership?.team || null;

        const postLog: DocUpdate = {
          date: new Date(),
          status: DocStatuses.Active,
          description: `${profile.name} created a new post`,
          actor: nanoProfile(profile),
        };
        // post to the newsfeed right away so the user granting recognition sees
        // it immediately
        const post: WithCreatedAt<ActivityPost> = {
          title: `${receiver.name} earned ${activityLog.points} points`,
          sticky: false,
          url,
          network: props.network,
          createdAt: new Date(),
          description: activityLog.description,
          date: activityLog.date,
          points: activityLog.points,
          eventType: POST_TYPE.PointsEarned,
          profile: receiver,
          team,
          activityLog: activityLog,
          photoUrls: activityLog.photoUrls,
          avatar: receiver.photoURL || "",
          latestDocUpdate: postLog,
        };
        await setDoc(postRef, post);
      } else {
        console.error("unexpected missing activity log", { al });
      }
      setError("");
      setActiveStep(steps.length);
    } catch (e) {
      if (e instanceof Error) setError(e.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <RecognizeUsernames
            profiles={profiles}
            setProfile={setReceiver}
            selected={receiver}
            network={props.network}
          />
        );
      case 1:
        return (
          <RecognizeAwards
            network={props.network}
            setAwardType={setAwardType}
            value={awardType?.id}
          />
        );
      case 2:
        return (
          <RecognizePoints
            setPointsValue={setPointsValue}
            network={props.network}
            value={pointsValue?.id}
          />
        );
      case 3:
        return <RecognizeNote setNote={setNote} />;
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    resetForm();
    setActiveStep(0);
  };

  const validate = (step: number): [boolean, string] => {
    const { validation, field } = steps[step] || {};
    return isEmpty(field) ? [false, validation] : [true, ""];
  };

  const nextEnabled = validate(activeStep);
  const isLastStep = activeStep === steps.length - 1;

  console.log("RecognizeStepper", {
    profiles,
    profile,
    receiver,
    note,
    pointsValue,
    awardType,
    nextEnabled,
  });

  return (
    <Box p={3}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({ label }, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Box my={2}>{getStepContent(index)}</Box>
              <Box mb={1}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Wrap
                  condition={!nextEnabled[0]}
                  wrapper={(ch: React.ReactElement) => (
                    <Tooltip title={nextEnabled[1]}>
                      <span>{ch}</span>
                    </Tooltip>
                  )}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={isLastStep ? handleSubmit : handleNext}
                    className={classes.button}
                    disabled={!nextEnabled[0] || isSubmitting}
                  >
                    {isLastStep ? "Submit" : "Next"}
                  </Button>
                </Wrap>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {!isEmpty(error) && (
        <Alert sx={{ my: 2 }} severity="error">
          <AlertTitle>Oops, something went wrong</AlertTitle>
          {error}
        </Alert>
      )}
      <Collapse in={activeStep === steps.length}>
        <Box p={2} mx={1}>
          <Alert icon={<Celebration />} sx={{ mb: 2 }} severity="info">
            Thanks for recognizing your colleague! They'll be notified of your
            recognition.
          </Alert>
          <Button onClick={handleReset} className={classes.button}>
            Start Over
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Close
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
}
