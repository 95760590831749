import { NetworkSettings } from "@converge-collective/common/models/NetworkSettings";
import { shallowTSToDates } from "@converge-collective/common/util";
import EventIcon from "@mui/icons-material/Event";
import {
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useUser } from "reactfire";
import RecognizeModal from "~/components/RecognizeModal";
import Newsfeed from "~/components/dashboard/Newsfeed";
// import TeamLeaderboard from "~/components/dashboard/TeamLeaderboard";
import { permissions } from "@converge-collective/common/models/rbac/NetworkPermission";
import { ErrorBoundary } from "react-error-boundary";
import { LayoutNetworkChildProps } from "~/components/LayoutWithNetwork";
import { usePermission } from "~/hooks/usePermission";
import useProfile from "~/hooks/useProfile";
import ErrorFallback from "../ErrorFallback";
import { PromptAllowPushNotifications } from "../PromptNotifications";
import { StickyColumnLayout } from "../StickyColumnLayout";
import { MyChallenges } from "../challengesV2/MyChallenges";
import UpcomingEvents from "../events/UpcomingEvents";
import Points from "../user/Points";
import ProfilePreview from "../user/ProfilePreview";
import UserAvatar from "../user/UserAvatar";

export default function DashboardContent({
  network,
}: LayoutNetworkChildProps): React.ReactElement {
  const theme = useTheme();
  const mdDownMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = mdDownMatch;

  const { data: user } = useUser();
  const [profile] = useProfile(user?.uid);

  const { canPerform: canCreatePost } = usePermission(
    permissions.newsfeedCreatePost
  );
  const { canPerform: canLikePost } = usePermission(
    permissions.newsfeedLikePost
  );
  const { canPerform: canReplyToPost } = usePermission(
    permissions.newsfeedReplyPost
  );

  const { settings } = network;
  const { isPeerRecognitionEnabled } = shallowTSToDates(
    settings || ({} as NetworkSettings)
  );

  return (
    <StickyColumnLayout
      center={
        <>
          <PromptAllowPushNotifications />
          {profile && (
            <Chip
              sx={{
                mb: 2,
                display: "flex",
                "& .MuiChip-label": { width: "100%" },
              }}
              avatar={<UserAvatar profile={profile} />}
              label={
                <Stack justifyContent="space-between" direction="row">
                  <ProfilePreview profile={profile} />
                  <Points network={network} profile={profile} />
                </Stack>
              }
            />
          )}
          {isPeerRecognitionEnabled === true ? (
            <RecognizeModal network={network} />
          ) : null}
          {isMobile && <UpcomingEvents network={network} />}
          <Newsfeed
            collectionName="network"
            allowCreatePost={Boolean(profile && canCreatePost)}
            allowLikePosts={canLikePost}
            allowPostComments={canReplyToPost}
            postsParent={network.ref}
            network={network}
          />
        </>
      }
      right={
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {profile?.currentNetworkMembership && (
              <MyChallenges network={network} profile={profile} />
            )}
          </ErrorBoundary>

          <Chip
            sx={{
              mb: 2,
              display: "flex",
              "& .MuiChip-label": { width: "100%" },
            }}
            icon={<EventIcon color="primary" />}
            label={
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Upcoming Events
              </Typography>
            }
          />
          <UpcomingEvents network={network} />
        </>
      }
    />
  );
}
