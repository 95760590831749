import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { NanoProfile } from "@converge-collective/common/models/Profile";
import { isEmpty, last } from "lodash";
import React from "react";
import UserAutocomplete from "./user/UserAutocomplete";

export default function RecognizeUsernames(props: {
  network: WithRef<Network>;
  profiles: NanoProfile[];
  setProfile: (profile: NanoProfile | undefined) => void;
  selected?: NanoProfile;
}): React.ReactElement {
  console.log("RecognizeUsernames", {
    profiles: props.profiles,
    selected: props.selected,
  });
  return !isEmpty(props.profiles) ? (
    <UserAutocomplete
      multiple
      selected={props.selected ? [props.selected] : null}
      profiles={props.profiles}
      onUserSelected={(u) => {
        console.log("RecognizeUsernames onUserSelected", u);
        const user = last(u);
        props.setProfile(user);
      }}
      label="Select a Colleague to Recognize"
    />
  ) : (
    <div>No profiles</div>
  );
}
