import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { default as React } from "react";
import { useSigninCheck } from "reactfire";
import DashboardContent from "~/components/dashboard/DashboardContent";
import LayoutWithNetwork from "~/components/LayoutWithNetwork";

// Support logged out marketing pages. This is better than e.g. creating a
// pages/billings.tsx because if we do that, router.query.network will be
// undefined and useNetwork would have to parse the network slug out of the url
// manually.
const marketingPages = {
  // "harvest-church": dynamic(
  //   () => import("../../components/marketing/Harvest"),
  //   {
  //     ssr: true,
  //   }
  // ),
  billings: dynamic(() => import("../../components/marketing/Billings"), {
    ssr: true,
  }),
};

export default function Dashboard(): React.ReactElement {
  const { data: signInCheckResult } = useSigninCheck();
  const router = useRouter();

  const network = (router.query?.network as string) || "";
  // check to see if this is one of our marketing pages
  if (signInCheckResult?.signedIn !== true && network in marketingPages) {
    // dynamically load the marketing page
    return React.createElement(marketingPages[router.query.network as string]);
  } else {
    return <LayoutWithNetwork pageName="Feed" Component={DashboardContent} />;
  }
}
