import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { DefaultPeerRecognitionSettings } from "@converge-collective/common/models/NetworkSettings";
import { PeerRecognitionPointValue } from "@converge-collective/common/models/PeerRecognition";
import { Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

export default function RecognizePoints({
  network,
  setPointsValue,
  value,
}: {
  network: WithRef<Network>;
  setPointsValue: (pv: PeerRecognitionPointValue) => void;
  value?: string;
}): React.ReactElement {
  const pointValues = (
    network?.settings?.peerRecognition || DefaultPeerRecognitionSettings
  ).pointValues;
  const pointValuesById = Object.fromEntries(
    pointValues.map((pv) => [pv.id, pv])
  );

  const handleChange = (event: SelectChangeEvent) => {
    const id: string = event.target.value;
    const pv = pointValuesById[id];
    if (pv) {
      setPointsValue(pv);
    } else {
      console.warn(`No point value found for id ${id}`);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="list-of-points">Points</InputLabel>
      <Select
        labelId="list-of-points"
        id="list-of-points-outlined"
        onChange={handleChange}
        value={value}
        label="Points"
      >
        {pointValues.map((pointValue) => (
          <MenuItem key={pointValue.id} value={pointValue.id}>
            <Chip
              sx={{ ml: 0, mr: 1 }}
              color="primary"
              variant="outlined"
              label={`${pointValue.points} points`}
            />
            {pointValue.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
