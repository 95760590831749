import { Box, SxProps } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import { useIsMobile } from "~/hooks/mobile";

/**
 * creates a 3 column layout for desktop with sticky left and right columns
 * and uses tabs for mobile
 */
export function StickyColumnLayout({
  left,
  center,
  right,
  cols,
}: {
  left?: React.ReactNode;
  center: React.ReactNode;
  right?: React.ReactNode;
  cols?: [number, number, number];
}) {
  const sticky: SxProps = {
    position: "sticky",
    top: 90,
    maxHeight: "calc(100vh - 112px)",
    overflow: "auto",
    mb: 2.2,
  };

  const isMobile = useIsMobile();
  const [leftCols, centerCols, rightCols] = cols
    ? cols
    : left
      ? [3, 6, 3]
      : [0, 8, 4];

  // mobile only shows the center column
  return isMobile ? (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{center}</ErrorBoundary>
  ) : (
    <Grid
      style={{
        height: "100%",
        width: "100%",
      }}
      container
      spacing={2}
    >
      {left && (
        <Grid size={{ sm: 12, md: leftCols }}>
          <Box sx={sticky}>{left}</Box>
        </Grid>
      )}
      <Grid size={{ sm: 12, md: centerCols }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {center}
        </ErrorBoundary>
      </Grid>
      {right && (
        <Grid size={{ sm: 12, md: rightCols }}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Box sx={sticky}>{right}</Box>
          </ErrorBoundary>
        </Grid>
      )}
    </Grid>
  );
}
