import { formatNumber } from "@converge-collective/common/util";
import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import { useProfilePoints } from "~/hooks/useProfilePoints";
import Wrap from "../Wrap";

export default function Points({
  profile,
  network,
  wrapper = (children) => <>{children}</>,
}: {
  profile: WithRef<Profile>;
  network: WithRef<Network>;
  wrapper?: (children: React.ReactElement) => JSX.Element;
}): React.ReactElement {
  const profilePoints = useProfilePoints(profile, network);

  if (!profilePoints) {
    return <></>;
  }

  const {
    points,
    peerRecognitionPoints,
    goalPoints,
    categoryPoints,
    rewardRedemptionPoints,
  } = profilePoints;

  const tooltipTitle = (
    <>
      Peer recognition: {formatNumber(peerRecognitionPoints)} points
      <br />
      Goals: {formatNumber(goalPoints)} points
      <br />
      Activities: {formatNumber(categoryPoints)} points
      <br />
      Reward redemptions: {formatNumber(rewardRedemptionPoints)} points
    </>
  );

  return (
    <Wrap condition={Boolean(wrapper)} wrapper={wrapper}>
      <Stack justifyContent="space-between" direction="row">
        <Tooltip arrow title={tooltipTitle}>
          <Typography sx={{ color: "text.secondary" }} variant="body1">
            {formatNumber(points)} points
          </Typography>
        </Tooltip>
      </Stack>
    </Wrap>
  );
}
