import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";
import { Profile } from "@converge-collective/common/models/Profile";
import { ProfilePoints } from "@converge-collective/common/models/ProfilePoints";
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { converters } from "~/lib/converter";

/**
 * Given a network and profile, return a snapshot of the user's latest points
 * for that network
 */
export const useProfilePoints = (
  profile: WithRef<Profile> | null | undefined,
  network?: WithRef<Network>
): ProfilePoints | null => {
  const firestore = useFirestore();
  const { data: profilePoints } = useFirestoreCollectionData(
    query(
      collection(
        profile?.ref || doc(firestore, "noop/noop"),
        "points"
      ).withConverter(converters.profilePoints.read),
      // we can never trust the types of anything provided by reactfire hooks :(
      where("networkId", "==", network?.id || "noop"),
      orderBy("date", "desc"),
      limit(1)
    )
  );

  return !isEmpty(profilePoints) ? profilePoints[0] : null;
};
